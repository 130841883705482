<template>
  <div>
    <v-dialog width="750" v-model="saveDialog">
      <v-card class="mt-n15" v-if="!saveOrSaveAs">
        <v-toolbar elevation="0" class="text mt-15">
          <v-toolbar-title v-if="isSaveable" data-cy="saveable"
            >Your structure is valid. You can save it now.</v-toolbar-title
          >
          <v-toolbar-title v-if="isCreated" class="text"
            >Your structure has been saved successfully.</v-toolbar-title
          >
          <v-toolbar-title v-if="editOld" v-model="label" class="text"
            >You are in the process of overwriting {{ label }}. <br />
            Do you want to proceed?</v-toolbar-title
          >
          <v-toolbar-title v-if="duplicateName" v-model="label" class="text"
            >'{{ label }}' already exists. <br />
            Please choose a unique label for every lattice.</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text v-if="loading">
          <v-row>
            <v-col cols="5" />
            <v-col cols="2"><LoadingIndicator data-cy="loading" /></v-col>
            <v-col cols="5" />
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="isSaveable || editOld"
            text
            color="darkGrey"
            @click="saveDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="isSaveable"
            dark
            :loading="loading"
            class="save"
            rounded
            depressed
            color="hqsOrange"
            @click="prepareSaving"
          >
            Save
          </v-btn>
          <v-btn
            v-if="editOld"
            class="confirm-overwrite-lattice"
            rounded
            depressed
            dark
            color="hqsOrange"
            @click="overwrite"
          >
            overwrite
          </v-btn>
          <v-btn
            v-if="isCreated || duplicateName"
            class="confirm-saving-lattice"
            rounded
            depressed
            dark
            color="hqsOrange"
            @click="saveDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="saveOrSaveAs" class="mt-n15">
        <v-toolbar elevation="0" class="text mt-15">
          <v-toolbar-title v-model="label" class="text"
            >Do you want to overwrite the old lattice of <br />
            {{ oldName }} with {{ label }}?</v-toolbar-title
          >
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            data-cy="overwrite"
            rounded
            dark
            color="hqsOrange"
            @click="overwrite"
          >
            overwrite
          </v-btn>
        </v-card-actions>
        <v-toolbar-title v-model="label" class="text ml-4">
          Or do you want to save {{ label }} additionally?</v-toolbar-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="darkGrey" @click="saveDialog = false">
            cancel
          </v-btn>
          <v-btn
            data-cy="create_new_lattice"
            depressed
            rounded
            dark
            color="hqsOrange"
            @click="createNew"
          >
            create new lattice
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="750" v-model="errorDialog">
      <v-card class="mt-n15">
        <v-toolbar elevation="0" class="text mt-15">
          <!-- the following v-if is existing purely to enable better e2e testing-->
          <v-toolbar-title
            class="text"
            data-cy="not_saveable"
            v-if="errorDialog"
            >Your structure can not be saved.</v-toolbar-title
          >
        </v-toolbar>
        <ValidationErrorAlert
          v-bind:errorMessage="errorMessage"
          v-bind:errorValidateData="errorValidateData"
        ></ValidationErrorAlert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            data-cy="confirm"
            rounded
            depressed
            dark
            color="hqsOrange"
            @click="errorDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const ValidationErrorAlert = () =>
  import(/* webpackChunkName: "alerts" */ './ValidationErrorAlert.vue')
const LoadingIndicator = () =>
  import(/* webpackChunkName: "utils" */ '../general/LoadingIndicator')
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    ValidationErrorAlert,
    LoadingIndicator,
  },

  data: () => ({
    saveDialog: false,
    errorDialog: false,
    oldName: '',
    isSaveable: false,
    errorMessage: '',
    errorValidateData: '',
    duplicateName: false,
    saveOrSaveAs: false,
    hasId: false,
    hasUniqueName: false,
    isCreated: false,
    editOld: false,
    loading: false,
    validatedInput: {},
  }),

  props: ['clear'],

  methods: {
    ...mapActions('backend', ['create', 'load', 'update']),
    ...mapMutations('backend', ['set']),
    ...mapActions('latticeBackend', ['validate']),
    ...mapMutations('lattice', ['setId', 'loadStructure', 'resetLattice']),

    prepareValidation() {
      this.errorDialog = false
      this.saveDialog = false
      this.hasId = false
      this.hasUniqueName = false
      this.editOld = false
      this.duplicateName = false
      this.saveOrSaveAs = false
      this.isCreated = false
      if (!this.datas.some((e) => e.label === this.label)) {
        this.hasUniqueName = true
      }
      if (this._uuid !== null && this._uuid !== '') {
        this.hasId = true
      }
      this.getValidation()
    },

    async getValidation() {
      this.isSaveable = false
      try {
        this.validatedInput = await this.validate(this.getLattice)
        this.isSaveable = true
      } catch (e) {
        this.catchValidationError(e)
      }
      this.saveDialog = true
    },

    catchValidationError(error) {
      this.errorMessage = error.error
      if (error === null) {
        this.errorValidateData =
          'An unknown error occured - please try again later.'
      } else if (JSON.stringify(error.error_data) !== JSON.stringify({})) {
        let errorText = JSON.stringify(error.error_data, null, 4)
        errorText = errorText.replaceAll('atom', 'site')
        this.errorValidateData = errorText
      } else {
        this.errorValidateData = ''
      }
      this.errorDialog = true
    },

    async prepareSaving() {
      this.isSaveable = false
      this.loading = true
      // Create new
      if (!this.hasId && this.hasUniqueName) {
        this.createNew()
        // edit old lattice and save with same label
      } else if (this.hasId && !this.hasUniqueName) {
        this.editOld = true
        this.loading = false
        // Create new lattice from old with new label
      } else if (this.hasId && this.hasUniqueName) {
        try {
          let element = this.datas.find((e) => e._uuid === this._uuid)
          this.oldName = element.label
          this.saveOrSaveAs = true
        } catch (error) {
          console.log('error', error)
        }
        // Duplicate label
      } else if (!this.hasId && !this.hasUniqueName) {
        this.loading = false
        this.duplicateName = true
      }
      this.saveDialog = true
    },

    async createNew() {
      try {
        let dataNode = this.getDataNode(this.validatedInput)
        await this.create(['data', dataNode])
        this.isCreated = true
        await this.load(['data'])
        this.$emit('clearFormInParent')
      } catch (error) {
        console.log('error', error)
        this.errorMessage = 'Sorry, your structure can not be saved.'
        this.errorDialog = true
      }
      this.loading = false
      this.saveDialog = true
      this.saveOrSaveAs = false
    },

    async overwrite() {
      this.saveDialog = false
      this.editOld = false
      this.saveOrSaveAs = false
      let dataNode = this.getDataNode(this.validatedInput)
      try {
        await this.update(['data', this._uuid, dataNode])
        await this.load(['data'])
        this.$emit('clearFormInParent')
      } catch (e) {
        console.log('lattice update error', e)
      }
      this.loading = false
      this.isCreated = true
      this.saveDialog = true
    },

  },

  computed: {
    ...mapFields('lattice', ['label']),

    ...mapGetters('lattice', ['getLattice', 'getDataNode']),

    ...mapState('lattice', {
      _uuid: (state) => state._uuid,
    }),

    ...mapState('backend', {
      datas: (state) => state.datas,
    }),
  },
}
</script>

<style scoped>
.loading {
  text-align: center;
}

.spinner {
  display: inline-block;
}
</style>
